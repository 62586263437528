import { Component, Input } from '@angular/core';
import { AbstractControl, FormGroup, UntypedFormGroup } from '@angular/forms';
import { RenderableBase } from '../Models/renderable-base';
import { RenderableRange } from '../Models/renderable-range';
import { DataService } from '../Services/data.service';
import { ViewControlService } from '../Services/view-control.service';
import { Utils } from '../Utils/utils';
import { BaseFormControlComponent } from '../Components/base-form-control.component';
import { VocabOption } from '../Models/vocaboption';
import { SurveyService } from '../Services/survey.service';

@Component({
  selector: 'app-ws-range',
  templateUrl: '../Templates/range.component.html',
  styleUrls: ['../Styles/range.component.scss'],
})

export class RangeComponent extends BaseFormControlComponent {
  protected override _renderable: RenderableRange;
  @Input()
  public override get renderable(): RenderableRange {
    return this._renderable;
  }
  public override set renderable(value: RenderableBase<any>) {
    this._renderable = value as RenderableRange;
  }
  protected override _form: FormGroup<any>;
  @Input()
  override set form(control: AbstractControl) {
    this._form = control as FormGroup<any>;
  }
  override get form(): FormGroup<any> {
    return this._form;
  }

  constructor(private dataService: DataService,
    private viewControlService: ViewControlService,
    surveyService: SurveyService) {
    super(surveyService);
  }

  public getOptions(): VocabOption[] {

    const options: VocabOption[] = [];
    this.renderable.options.forEach((opt) => {
      options.push(new VocabOption(opt));
    });

    const _data = this.dataService.getData();
    const context = { data: _data };
    let start = Utils.maskedEval(this.renderable.start, context);
    let end = Utils.maskedEval(this.renderable.end, context);
    let step = this.renderable.stepAsNumber;

    let direction = 'asc';

    // Sanity check for missing 'start' parameter.
    // E.g. in dependent ranges: CHECK yyochld and yyyochld
    if (!start) {
      start = end - 20;
    }

    // Sanity check for missing 'end' parameter.
    // W.g. in dependent ranges: CHECK yyochld and yyyochld
    if (!end) {
      end = start + 20;
    }

    // make sure we step into the right direction..
    if (start < end) {
      step = Math.abs(step);
    } else {
      direction = 'desc';
      step = -1 * Math.abs(step);
    }

    for (let idx = start; idx !== end; idx += step) {
      const opt = new VocabOption({ defaultValue: false, value: idx, label: idx });
      options.push(opt);

      // Safety break. We might also check for (end % step == 0)
      if ((direction === 'asc' && idx > end) || (direction === 'desc' && idx < end)) {
        break;
      }
    }

    // include the end point
    const opt = new VocabOption({ defaultValue: false, value: end, label: end });
    options.push(opt);

    return options;
  }

  public trackByFunction(idx, val) {
    return val.value;
  }

  public needsFill(value: number): boolean {
    let result = false;

    if (this.renderable['show_fill']) {
      if (this.form.controls[this.renderable.id].value) {
        result = +value <= +this.form.controls[this.renderable.id].value;
      }
    }

    return result;
  }

  public inactive(value: number) {
    /* a bit of a hack: we can't change the 'active' class on the radiogroup
     * this seems to be impossible. So we just add an inactive class and add
     * some special inactive styling to the scss
     */
    return String(this.form.controls[this.renderable.id].value) !== String(value);
  }


  public someOpts() {
    const r = [];
    for (let idx = 0; idx < this.getOptions().length; idx++) {
      r.push(idx);
    }
    return r;
  }

  public numberOfOptions() {
    return this.getOptions().length;
  }

}
