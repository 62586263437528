import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { ModelService } from './model.service';
import { GlobalService } from './global.service';
import { RenderableBase } from '../Models/renderable-base';
import { RenderableGroup } from '../Models/renderable-group';
import { Logger } from './logger.service';
import { LocalStorageService } from '../Services/local-storage.service';
import { Map as ImmutableMap } from 'immutable';
import { Subject } from 'rxjs';
import { Utils } from '../Utils/utils';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  // subscribers can find out if the data has changed
  public dataRefreshed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public dataChanged: Subject<boolean> = new Subject<boolean>();

  // _flatData is a flattened (1 level) structure of all angular form fields
  // the key is the bind, not the ID of the field. The value is a list
  // of fields, probably always one. But in theory several fields can have
  // the same bind
  public readonly flatData$: Observable<{}>;
  public renderablesByBind = new Map<string, RenderableBase<any>>();

  public dataConverters = {
    boolean: (val: any) => !!val,
    string: (val: any) => val.toString(),
    int: (val: any) => +val,
    float: (val: any) => +val,
    object: (val: any) => val,
    date: (val: any) => new Date(val),
    time: (val: any) => val.toString(), // hope for the best..
    // list: (val) => ?? TODO
  };

  protected readonly _flatData: BehaviorSubject<{}> = new BehaviorSubject({});

  // _rawData holds the JSON data structure as received by the rest backend
  protected _rawData: {} = null;
  // _queryParams: holds the request parameters (prefilled data)
  protected _queryParams: {} = null;

  // _pageMetaData holds meta data related to pages, like timing
  protected _pageMetaData: {} = {};

  protected idToBindMapping: {} = {};
  protected _formgroup: UntypedFormGroup = null;
  protected _cachedData = ImmutableMap({});
  protected _cachedDataJs = null; // for some reasone the toJS method is very slow

  constructor(
    private modelService: ModelService,
    private sessionStorage: LocalStorageService,
    private logger: Logger,
    public globals: GlobalService,
  ) {
    this.flatData$ = this._flatData.asObservable();
    this.sessionStorage.setStorageType('session')
  }


  private set flatData(val: {}) {
    this._flatData.next(val);
  }

  public saveGlobalsToSession() {
    this.sessionStorage.set('MAINLOCALE', this.globals.locale);
    this.sessionStorage.set('SURVEYID', this.globals.surveyId);
  }

  public loadSessionToGlobals() {
    this.globals.locale = this.sessionStorage.get('MAINLOCALE');
    this.globals.surveyId = this.sessionStorage.get('SURVEYID');
  }

  /*
   * clear all session data, clear all form control values any
   * reinitialize the form data
   * note that we won't recalculate initial server (json) generated value
   * like the RANDOM values
   */
  public restartSurvey() {
    // clear all session data
    this.sessionStorage.clear(/.*/);

    // clear the meta data for page timings
    this.clearPageMetaData();

    // clear the cached data
    // this._cachedData = ImmutableMap({});
    this._cachedData = null;
    this._cachedDataJs = null;

    this.saveGlobalsToSession();

    if (this._formgroup) {
      // reset all form controls. first get a map of all values
      const values = this._formgroup.value;
      // now reset all values to null
      for (const k of Object.keys(values)) {
        values[k] = null;
      }
      // reset the formgroup
      this._formgroup.reset();

      // initialize the form controls with default values from the json data
      // TODO: will emit false work in all scenarios?
      this._patchFormValues(false);
    }

    this.refreshData(true, false);
  }

  public loadPrefilledDataFromQueryParams() {
    // prepopulate rawData using queryParams.
    // TODO: consider prepopulating existing keys only
    for (const qp in this._queryParams) {
      if (this._queryParams.hasOwnProperty(qp)) {
        this._rawData[qp] = this._queryParams[qp];
      }
    }
  }

  public setRawData(data: { data?: {} } = {}, queryParams: {}) {
    this._rawData = data;
    this._queryParams = queryParams;
    this.loadPrefilledDataFromQueryParams();
    this._patchFormValues();
  }

  public setPageMetaValue(key: string, value: any): void {
    this._pageMetaData[key] = value;
  }

  public clearPageMetaData() {
    this._pageMetaData = {};
  }

  public getPageMetaData() {
    return this._pageMetaData;
  }

  private recursiveCreateRenderablesMapping(renderables: Array<RenderableBase<any>>) {
    renderables.forEach((r) => {
      if (r instanceof RenderableGroup) {
        this.recursiveCreateRenderablesMapping(r.subrenderables);
      }
      if (r.id && r.bind) {
        this.idToBindMapping[r.id] = r.bind;
        this.renderablesByBind[r.bind] = r;
      }
    });
  }

  public setFormModel(formgroup: UntypedFormGroup, renderables: Array<RenderableBase<any>>) {
    // populate the idToBindMapping
    this.recursiveCreateRenderablesMapping(renderables);
    this._formgroup = formgroup;

    // clear old data structure. Don't know if we can
    // just replace the flatData here since it's being
    // observed I think..
    const data = this.getData();
    let flatData = this._flatData.getValue();
    for (const bind in data) {
      if (flatData[bind] instanceof Array) {
        delete flatData[bind];
      }
    }

    this._recursiveFlattenData(this._formgroup);
    this._patchFormValues();
    this._flatData.next(flatData); // allow subscribers to bind to e.g. locale field
  }

  public getFlatData() {
    return this._flatData;
  }

  public setRawValue(bind: string, value: any) {
    if (this._rawData && bind in this._rawData) {
      this._rawData[bind] = value;
    }
  }

  public setValue(bind: string, value: any): boolean {

    // if (bind == 'locale') {
    //   // terrible? hack.. don't allow setting locale since this interferes
    //   // with e.g. the searchtrees
    //   return;
    // }

    // set the value in the flat data
    let changed = false;
    const flatData = this._flatData.getValue();
    if (bind in flatData) {
      // note: if we don't emit events we stop others from observing changes..
      for (const control of flatData[bind]) {
        if (value !== control.value) {
          changed = true;
          control.setValue(value, { emitEvent: true });
          control.markAsTouched();
          this.renderablesByBind[bind].setValue(value);
        }
      }
    } else if (this._rawData && bind in this._rawData) {
      // TODO: not sure if we should write directly to rawData in case of restarting offline  
      if (value !== this._rawData[bind]) {
        changed = true;
        this._rawData[bind] = value;
      }
    }
    return changed;
  }

  public uniqueId(length = 32) {
    let id = '';
    while (id.length < length) {
      id += Math.random().toString(36).substr(2);
    }
    return id.substr(0, length);
  }

  /*
   * save all flatData to localStorage
   */
  public saveLocalStorage() {
    const flatData = this._flatData.getValue();
    for (const bind in flatData) {
      if (flatData[bind] instanceof Array) {
        for (const control of flatData[bind]) {
          if (control instanceof AbstractControl) {
            this.sessionStorage.set(bind, control.value);
          }
        }
      }
    }
    // save page meta variables
    for (const k in this._pageMetaData) {
      if (this._pageMetaData.hasOwnProperty(k)) {
        this.sessionStorage.set(k, this._pageMetaData[k]);
      }
    }
  }

  /* This has the smell of an antipattern.. we use the model to convert
   * the values to the correct datatype. The best place to convert this
   * is probably in the renderables (models) or in the view service.
   * but this is way more convenient, so for now it's here
   */
  public convertField(bind: string, value: any) {
    if (value == null || value === '') {
      return null; // we don't convert empty nulls.. I think
    }

    const props = this.modelService.getProperties()[bind];
    for (const prop in props) {
      if (props.hasOwnProperty(prop)) {
        const datatype = props[prop]['datatype'];
        if (datatype) {
          if (datatype in this.dataConverters) {
            try {
              const converted = this.dataConverters[datatype](value);
              return converted;
            } catch (e) {
              this.logger.error(`Could not convert value ${value} for bind ${bind}`);
            }
          }
        }
      }
    }
    return value; // default: no datatype was specified
  }

  public refreshData(changed: boolean, emitDataChanged: boolean) : void{

    if (!changed && this._cachedData && this._cachedData.size) {
      return;
    }

    if (!this._cachedData) {
      this._cachedData = ImmutableMap();
    }

    const cloned = ImmutableMap({ ...this._cachedData.toJS() });

    const newValues = {};
    const flatData = this._flatData.getValue();
    for (const bind in flatData) {
      if (flatData[bind] instanceof Array) {
        for (const control of flatData[bind]) {
          if (control instanceof AbstractControl) {
            newValues[bind] = this.convertField(bind, control.value);
            this.renderablesByBind[bind].setValue(newValues[bind]);
            try {
              const lexicalValue = this.renderablesByBind[bind].lexicalValue();
              newValues[`${bind}:lexical`] = lexicalValue;
            } catch (error) {
              // pass (lexicalValue not implemented)
            }
          }
        }
      }
    }

    // some raw values (e.g. external data) might not be reflected in renderables
    for (const bind in this._rawData) {
      if (!(bind in newValues)) {
        newValues[bind] = this.convertField(bind, this._rawData[bind]);
      }
    }

    this._cachedData = ImmutableMap(newValues);

    if (!this._cachedData.equals(cloned)) {
      this._cachedDataJs = this._cachedData.toJS();
      this.dataRefreshed.next(true);
      if (emitDataChanged) {
        this.dataChanged.next(true);
      }
    }
  }

  public getData(): {} {
    if (!this._cachedData || !this._cachedData.size) {
      this.refreshData(false, false);
    }

    // we need to cache the toJS output since it's very slow..
    if (!this._cachedDataJs) {
      this._cachedDataJs = this._cachedData.toJS();
    }

    return this._cachedDataJs;
  }

  public getDataForSubmission() {
    const submissionData = {};
    this._recursiveDataForSubmission(this._formgroup, submissionData);
    return submissionData;
  }

  /* return all fields (IDS) that are in the form */
  public getFields() {
    return Object.keys(this.idToBindMapping);
  }

  public getItem(id: string) {
    return this.getData()[id] || null;
  }

  protected _recursiveFlattenData(formgroup: UntypedFormGroup) {
    if (!this._flatData || !this._rawData) {
      // not fully initialized..
      return;
    }

    let flatData = this._flatData.getValue();
    for (const control in formgroup.controls) {
      if (formgroup.controls.hasOwnProperty(control)) {
        const ctrl = formgroup.controls[control];
        const bind = this.idToBindMapping[control];
        if (bind in this._rawData) {
          if (bind in flatData) {
            flatData[bind].push(ctrl);
          } else {
            flatData[bind] = [ctrl];
          }
        }
        if (ctrl instanceof UntypedFormGroup) {
          this._recursiveFlattenData(ctrl);
        }
      }
    }

    this._flatData.next(flatData); // not sure about this one
    this.refreshData(true, false);
  }

  /*
   * w20e.forms (backend) expects the http post with IDs of the fields
   * (so not the bind names)
   * so we need to convert the data recursively
   */
  protected _recursiveDataForSubmission(formgroup: UntypedFormGroup, submissionData: {}) {
    for (const controlId in formgroup.controls) {
      if (formgroup.controls.hasOwnProperty(controlId)) {
        const ctrl = formgroup.controls[controlId];
        const bind = this.idToBindMapping[controlId];
        if (bind in this._rawData) {
          submissionData[controlId] = ctrl.value;
          // or should we convert the value first?
          // submissionData[controlId] = this.convertField(bind, ctrl.value);
        }
        if (ctrl instanceof UntypedFormGroup) {
          this._recursiveDataForSubmission(ctrl, submissionData);
        }
      }
    }
  }

  /*
   * set all raw (json) data on the formcontroller values
   */
  protected _patchFormValues(emit: boolean = true) {
    const flatData = this._flatData.getValue();
    for (const bind in this._rawData) {
      if (bind in flatData) {

        // set the default values
        const props = this.modelService.getProperties()[bind];
        for (const prop in props) {
          if (props.hasOwnProperty(prop)) {
            const defaultValue = props[prop]['defaultValue'];
            if (defaultValue) {
              // default might be an expression, so calculate it
              const value = Utils.maskedEval(defaultValue, { data: this.getData() });
              this.setRawValue(bind, value)
            }
          }
        }

        for (const control of flatData[bind]) {
          if (control instanceof AbstractControl && !(control instanceof UntypedFormGroup)) {
            let value = null;
            // note that params from request (prefilled data) has a higher weight when
            // we decide when we want to load the saved data from the session.
            // this is e.g. handy when you want to restart the survey with another
            // prefilled parameter (e.g. referrer in DWC)
            // (the prefilled data is already in the _rawData)
            if (this._queryParams.hasOwnProperty(bind)) {
              value = this._rawData[bind];
            }
            if (bind === 'locale') {
              // yes this mapping is weird
              value = this.globals['_displayLocale'];
            }
            if (bind === 'referrer') {
              value = this.globals['referrer'];
            }
            if (bind === 'referrer2') {
              value = this.globals['referrer2'];
            }
            if (value === null) {
              value = this.sessionStorage.get(bind);
              if (value === null) {
                value = this._rawData[bind];
              }
            }
            control.setValue(value, { emitEvent: emit });
            this.renderablesByBind[bind].setValue(value);
          }
        }
      }
    }

    // initialize session-id if it's not already
    if ('session-id' in flatData) {
      const sessionIdControl = flatData['session-id'][0];
      if (!(sessionIdControl as any).value) {
        const sessionId = this.uniqueId();
        (sessionIdControl as any).setValue(sessionId, { emitEvent: false });
        const sessionRenderable = this.renderablesByBind['session-id'];
        if (sessionRenderable) {
          sessionRenderable.setValue(sessionId);
        }
      }
    }

    // restore page timing variables from localstorage if present
    for (const k of this.sessionStorage.keys()) {
      if (k.startsWith('__page_')) {
        this._pageMetaData[k] = this.sessionStorage.get(k);
      }
      if (k == '__start__') {
        this._pageMetaData[k] = this.sessionStorage.get(k);
      }
    }
    if (!('__start__' in this._pageMetaData)) {
      this._pageMetaData['__start__'] = Math.floor(Date.now() / 1000);
    }

    this.refreshData(true, false);
  }
}
