<page-group *ngIf="renderable.type=='pagegroup'" [additionalClasses]="additionalClasses()" [renderable]="renderable"
  [form]="form.controls[renderable.id]"></page-group>

<div [formGroup]="form" *ngIf="form && renderable.type != 'pagegroup' && (renderable|wsIsRelevant|async)">

  <div [ngSwitch]="renderable.type"
    [attr.class]="(renderable.type != 'hidden') ? 'form-group card card-ws' : 'form-group d-none'">

    <div [class]="additionalClasses() +  ' mt-1 mb-1'">

      <div class="extraQuestionHeaders d-flex flex-row flex-wrap">
        <template #questionHeadersContainer></template>
      </div>

      <div part="card-title" class="card-title"
        *ngIf="['hidden', 'text', 'checkbox', 'submit'].indexOf(renderable.type) == -1" [attr.for]="renderable.id">

        <span *ngIf="showRenderableCounters() && renderable.bind" class="fw-bold fst-italic me-2 text-secondary"> {{renderable.counter}}.</span>

        <label *ngIf="renderable.label" [for]="renderable.id"
          class="fw-bolder text-primary mb-3">
          {{renderable.label|wsTrans:(renderable.msgid
          ||
          renderable.id):renderable.bind|async|wsInterpolate|async}}
          <fa-icon [icon]="['fas', 'info-circle']" class="ms-2 text-info"
            *ngIf="(renderable.info | wsTrans: '__if_exists__' + renderable.id + '_info' : '__if_exists__' + renderable.bind + '_info' | async | wsInterpolate | async) as condition"
            ngbTooltip="{{renderable.info | wsTrans: '__if_exists__' + renderable.id + '_info' : '__if_exists__' + renderable.bind + '_info' | async | wsInterpolate | async}}">
          </fa-icon>

          <fa-icon *ngIf="isRequired(renderable.bind)" [icon]="['fas', 'asterisk']" class="ws-required ms-2"></fa-icon>
        </label>

        <div class="text-muted"><small>{{ renderable.help | wsTrans: '__if_exists__' + renderable.id + '_help' :
            '__if_exists__' + renderable.bind + '_help'|async|wsInterpolate|async}}</small></div>
        <div class="text-muted"><small>{{ renderable.hint | wsTrans: '__if_exists__' + renderable.id + '_hint' :
            '__if_exists__' + renderable.bind + '_hint'|async|wsInterpolate|async}}</small></div>
      </div>

      <small *ngIf="globals.DEBUG && !(renderable.type=='hidden')" class="badge bg-success me-1">
        <fa-icon [icon]="['fas', 'link']"></fa-icon>
        bind={{ renderable.bind || '' }} ({{ debugRepresentation(renderable) }})
      </small>

      <small *ngIf="globals.DEBUG && !(renderable.type=='hidden')" class="badge bg-success me-1">
        <fa-icon [icon]="['fas', 'id-badge']"></fa-icon> id={{ renderable.id || 'null' }}
      </small>

      <small *ngIf="globals.DEBUG && !(renderable.type=='hidden') && renderable.msgid" class="badge bg-success me-1">
        <fa-icon [icon]="['fas', 'language']"></fa-icon> msgid={{ renderable.msgid || 'null' }}
      </small>

      <small *ngIf="globals.DEBUG && !(renderable.type=='hidden')" class="badge bg-info me-1">
        MASTER={{ renderable.label || 'null' }}
      </small>

      <div *ngIf="globals.DEBUG && renderable.type != 'hidden' &&
        wrapBaseFormControlComponent(form.controls[renderable.id])?.modprop">
        <span *ngFor="let key of ['relevant', 'constraint', 'calculate', 'datatype', 'required', 'defaultValue']">
          <ng-container *ngFor="let prop of wrapBaseFormControlComponent(form.controls[renderable.id])?.modprop">
            <small class="badge bg-warning me-2" *ngIf="prop[key]">
              {{ key + ':' + prop[key] }}
            </small>
          </ng-container>
        </span>
      </div>

      <div class="card-tags" *ngIf="globals.DEBUG && renderable.type != 'hidden'">
        <span *ngFor="let key of ['pristine', 'touched', 'dirty', 'status']">
          <small class="badge bg-primary me-1"
            *ngIf="form.controls[renderable.id] && form.controls[renderable.id][key]">
            {{ key + ':' + form.controls[renderable.id][key] }}
          </small>
        </span>
      </div>

      <div class="card-text" role="alert" *ngSwitchCase="'text'">
        <div class="row" *ngIf="renderable.output == 'raw'" appRunScripts
          [innerHTML]="renderable.text|wsInterpolate|async|sanitizeHtml"></div>
        <ng-container *ngIf="renderable.output != 'raw'">
          {{ renderable.text|wsTrans:(renderable.msgid || renderable.id):renderable.bind|async|wsInterpolate|async }}
        </ng-container>
      </div>

      <app-ws-searchtree *ngSwitchCase="'searchtree'" [form]="form" [renderable]="renderable"></app-ws-searchtree>

      <app-ws-checkbox *ngSwitchCase="'checkbox'" [form]="form" [renderable]="renderable"></app-ws-checkbox>

      <app-ws-input *ngSwitchCase="'input'" [form]="form" [renderable]="renderable"></app-ws-input>

      <app-ws-currency *ngSwitchCase="'currency'" [form]="form" [renderable]="renderable"></app-ws-currency>

      <app-ws-date *ngSwitchCase="'date'" [form]="form" [renderable]="renderable"></app-ws-date>

      <app-ws-date *ngSwitchCase="'month'" [form]="form" [renderable]="renderable"></app-ws-date>

      <ws-richtext *ngSwitchCase="'richtext'" [form]="form" [renderable]="renderable"></ws-richtext>

      <app-ws-hidden *ngSwitchCase="'hidden'" [form]="form" [renderable]="renderable"></app-ws-hidden>

      <app-ws-select *ngSwitchCase="'select'" [form]="form" [renderable]="renderable"></app-ws-select>

      <div *ngSwitchCase="'submit'">

        <ng-container
          *ngIf="parentCardGroup?.renderable.render_next_button === 'false'; then submitTemplate else nextTemplate"></ng-container>
        <ng-template #nextTemplate>
          <app-ws-nexttab [parentCardGroup]="parentCardGroup" [form]="form" [renderable]="renderable"></app-ws-nexttab>
        </ng-template>
        <ng-template #submitTemplate>
          <app-ws-submit [form]="form" [renderable]="renderable"></app-ws-submit>
        </ng-template>
      </div>


      <app-ws-range *ngSwitchCase="'range'" [form]="form" [renderable]="renderable"></app-ws-range>

      <app-ws-matrix *ngSwitchCase="'matrixgroup'" [class]="'matrixgroup'" [renderable]="renderable"
        [form]="form.controls[renderable.id]"></app-ws-matrix>

      <app-ws-flowgroup *ngSwitchCase="'flowgroup'" [parentCardGroup]="parentCardGroup" [class]="'flowgroup'"
        [renderable]="renderable" [renderables]="renderable.subrenderables"
        [form]="form.controls[renderable.id]"></app-ws-flowgroup>

      <app-ws-flowgroup *ngSwitchCase="'gridgroup'" [class]="'gridgroup'" [renderable]="renderable"
        [renderables]="renderable.subrenderables" [form]="form.controls[renderable.id]"></app-ws-flowgroup>

      <ws-cardgroup *ngSwitchCase="'cardgroup'" [renderable]="renderable" [class]="'cardgroup'"
        [renderables]="renderable.subrenderables" [form]="form.controls[renderable.id]"></ws-cardgroup>

    </div>
  </div>
</div>