export class VocabOption {
    public defaultValue: boolean;
    public value: number;
    public label: string;

    constructor(option) {
        this.defaultValue = option['defaultValue'] || option['default'];

        // try to convert value to number
        let value = parseInt(option['value']);
        if (isNaN(value)) {  // could be e.g. 'day'
            value = option['value'];
        }
        this.value = value;
        this.label = option['label'];
    }
}
